import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      // Question related variables
      answer_text : "",
      question_text: "",
      correct_answer: 0,

      // Game stats
      score: 0,
      lives: 5,
      difficulty: 10,

      game_over: false,
      answer_history: [],

      time_left: 60,
      time_question_start: 0
    }
    this.handleInput = this.handleInput.bind(this);
    this.generateQuestion = this.generateQuestion.bind(this);
    this.setState = this.setState.bind(this);
    this.restart = this.restart.bind(this);
    document.addEventListener("keydown",this.handleInput);
    setInterval(this.decreaseTime.bind(this),1000)
  }

  /* Every second this function is called and decreaeses the time remaining
  counter */
  decreaseTime () {
    this.setState ((state) => ({
      time_left: state.time_left - 1
    }));
    if (this.state.time_left === 0) {
      this.setState((state) => ({
        game_over: true,
        difficulty: 10
      }));
    } 
  }

  /* Runs at page load, generates initial question */
  componentDidMount() {
    this.generateQuestion();
  }
  
  /* Generates a random question */ 
  generateQuestion () {
    function getRandomInt(max) {
      return Math.floor(Math.random() * max);
    }

    let num1 = getRandomInt(this.state.difficulty);
    let num2 = getRandomInt(this.state.difficulty);
    this.setState((state) => ({
      num1: num1,
      num2: num2,
      question_text : num1 + " x " + num2,
      correct_answer: num1 * num2,
      answer_text: " ",
      time_question_start: Date.now()
    }));

  }

  restart () {
    this.setState( (state) => ({
      game_over: false,
      score: 0,
      difficulty: 10,
      lives: 5,
      time_left: 60,
      answer_history : []
    }));
    this.generateQuestion();
  }

  /* Handles all keyboard input */
  handleInput(keyPressed) {
    // If the key hit is a number we add it to the answer_text
    if (!isNaN(keyPressed.key)) {
      this.setState((state) => ({
        answer_text: state.answer_text + keyPressed.key
      }));

    // If the key hit is backspace we remove a character from answer_text
    } else if (keyPressed.key === 'Backspace') {
      this.setState((state) => ({
        answer_text: state.answer_text.slice(0, -1)
      }));

    // If the key hit is 'Enter' we check if the answer is correct
    } else if (keyPressed.key === 'Enter' && !this.state.game_over) {


      // Branch is the answer given is correct
      if (parseInt(this.state.answer_text) === this.state.correct_answer) {
        this.setState((state) => ({
          score: state.score + state.difficulty,
          difficulty: state.difficulty + 1
        }));

      // Branch is the answer given is incorrect
      } else {
        this.setState((state) => ({
          difficulty: state.difficulty - 1,
          lives: state.lives - 1
        }));
        
        // Check if the game is lost due to no lives
        if (this.state.lives === 0) {
          this.setState((state) => ({
            game_over: true
          }));
        }
      }

      // Add the question to the answer_history
      this.setState((state) => ({
        answer_history: [...this.state.answer_history, 
                         {question_text: this.state.question_text + " = " + this.state.correct_answer, 
                         time_taken: Date.now() - this.state.time_question_start, 
                         correct: parseInt(this.state.answer_text) === this.state.correct_answer}],
      }));
      this.generateQuestion();
    }
  }

  render() {
    // The screen used when the game is being played
    let game_screen = (     
      <div className="App">
        <header className="App-header">
          <h1>
            Infinite Times Tables
          </h1>
          
          <div>
          <h2 id="question">{this.state.question_text}</h2> 
          <div id="answer-box"><p id="answer-field" inputMode="numeric"> 
          {this.state.answer_text}</p></div>
          </div>
          <div id="stats">
            <h2 className="stat">Score: {this.state.score}</h2>
            <h2 className="stat">Lives: {this.state.lives}</h2>
          </div>
          <div>
          <p>Time remaining: {this.state.time_left}</p>
          </div>
        </header>
      </div>);

    // The screen after the game is over
    let end_screen = 
    <div className = "App">
    <header className = "App-header">
      <h1>Game is Over!</h1>
        <h2>Final Score: {this.state.score}</h2>
        <h3>Answers Given:</h3>
        <div className = "question_list_box">
          <ol>
            {this.state.answer_history.sort((a,b) => b.time_taken - a.time_taken).map((question, index) => (
              <li key="index" className="question_list">{question.question_text}</li>
            ))}
          </ol>
          <ol>
            {this.state.answer_history.sort((a,b) => b.time_taken - a.time_taken).map((question, index) => (
              <li key="index" className="question_list">{"Time taken: " +
            question.time_taken + "ms"}</li>
            ))}
          </ol>
          <ol>
            {this.state.answer_history.sort((a,b) => b.time_taken - a.time_taken).map((question, index) => (
              <li key="index" className="question_list">{question.correct ? "Correct" : "Incorrect"}</li>
            ))}
          </ol>
        </div>

        <button onClick={this.restart}>Restart Game</button>
      
    </header>
    </div>

    return (
      /* If the game is over we show a different end screen. */
      this.state.game_over ? end_screen : game_screen
    );
  }
 
}

ReactDOM.render(
  <App />, document.getElementById('root')
);
